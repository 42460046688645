* {
  font-family: Helvetica;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f5f5f0;
}

.body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 91vh;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

@media screen and (max-width: 650px) {
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 82vh;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
