.Articles-Segment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 220px;
}

.Articles-Articles {
  padding: 5px;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.Selected-Articles {
  padding: 5px;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.ui.card:first-child {
  margin-top: 1em;
}

.Articles-Button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.Article-Image {
  padding-top: 40px;
  padding-left: 10px;
  width: 120px;
  height: 120px;
}
.Article-Text {
  font-size: 16px;
  color: #0096d8;
  padding-left: 20px;
  padding-top: 90px;
}

@media screen and (max-width: 650px) {
  .room {
    width: 347px;
  }

  .Article-Image {
    padding-top: 60px;
    width: 110px;
    height: 110px;
  }

  .Article-Text {
    font-size: 16px;
    color: #0096d8;
    padding-left: 20px;
    padding-top: 80px;
  }
}
