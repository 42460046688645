.Home-Header-Desktop {
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Home-Header-Mobile {
  display: none;
}

.Segment {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.About-Header {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.About-MissionHeader {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.About-MissionText {
  display: flex;
  justify-content: center;
  padding: 10px;
}

/* Intro only Desktop */

.Desktop-Intro {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Title-Intro {
  display: flex;
  margin-top: 90px;
}

.Image-Intro {
  height: 100%;
  width: 100%;
}

/* Home */

.Home-Segment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Home-CTAButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.Home-Box {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}

.ImageMobile {
  display: none;
}

.ImageDektop {
  display: flex;
  justify-content: center;

  width: 80%;
  height: 100%;
}

.Home-Destkop-Box {
  padding-top: 20px;
  flex-direction: column;
}

.Home-Image {
  background-repeat: no-repeat;
  height: 60%;
  width: 60%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Home-Text {
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
}

.Link {
  color: white;
}

/* only mobile */

@media screen and (max-width: 650px) {
  .Home-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Home-Header-Mobile {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Home-CTAButton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .Home-Header-Desktop {
    display: none;
  }

  .Home-Text {
    width: 100%;
  }
  .ImageMobile {
    display: inline;
  }

  .Desktop-Intro {
    display: none;
  }

  .Title-Intro {
    display: none;
  }

  .Image-Intro {
    display: none;
  }

  .ImageDektop {
    display: none;
  }

  .Home-Image {
    background-size: 80%;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    width: 100%;
  }
}
