.error {
  color: red;
}
.loginArea {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.loginButton {
  padding-right: 5px;
  width: 100%;
  padding-bottom: 5px;
}
.Register {
  padding-top: 40px;
}
.logHeader {
  padding-bottom: 20px;
}

.loginTitel {
  padding-bottom: 20px;
}

.log {
  padding-left: 30px;
  padding-top: 10px;
}
