.Emptystate {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.Summary-Image {
  height: 200px;
}

.Button {
  padding-top: 20px;
}

.Mobile {
  display: none;
}

@media screen and (max-width: 650px) {
  .summary {
    width: 347px;
  }
  .Mobile {
    display: inline;
  }

  .Desktop {
    display: none;
  }
}
