.Rooms-Segment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 220px;
}

.Rooms-Rooms {
  padding: 5px;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.Selected-Rooms {
  padding: 5px;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.cardContent {
  display: flex;
  flex-wrap: wrap;
  width: 10px;
  height: 10px;
}

.ui.card:first-child {
  margin-top: 1em;
}

.Rooms-Button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.emptyState {
  display: flex;
  align-content: center;
}

.Rooms-Image {
  padding-top: 70px;
  padding-left: 20px;
  width: 80px;
  height: 80px;
}
.Rooms-Text {
  font-size: 16px;
  color: #0096d8;
  padding-left: 20px;
  padding-top: 90px;
}

.Mobile {
  display: none;
}

@media screen and (max-width: 650px) {
  .room {
    width: 347px;
  }
  .Mobile {
    display: inline;
  }

  .Desktop {
    display: none;
  }

  .Rooms-Image {
    padding-top: 90px;
    padding-left: 20px;
    width: 50px;
    height: 50px;
  }

  .Rooms-Text {
    font-size: 16px;
    color: #0096d8;
    padding-left: 20px;
    padding-top: 90px;
  }
}
