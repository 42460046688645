.Articles-Button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.Title {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 650px) {
  .articleDetails {
    width: 360px;
  }
}
