.headerTop {
  background-color: white;
  background: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.headerText {
  color: white;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
}

.Header-Link {
  color: black;
}

.Header-Login {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.Header-Image {
  padding-top: 10px;
  height: 35px;
}

@media screen and (max-width: 650px) {
  .Header-Image {
    padding: 10px;
    height: 50px;
  }
}
