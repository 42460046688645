.Footer {
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.15);
  padding-right: 2%;
  height: 40px;
}

.Footer-Link {
  color: black;
  padding-right: 20px;
  padding-top: 10px;
}
