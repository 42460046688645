.reg {
  color: black;
  padding-left: 20px;
}

.regHeader {
  padding-bottom: 10px;
}

.regContent {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.regInput {
  padding-bottom: 8px;
}
